import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable, of } from 'rxjs';
import { Supplier } from '../../models/Supplier';
import { environment } from '../../../environments/environment';

@Injectable({
  providedIn: 'root'
})
export class SupplierService {

  private apiUrl = environment.apiEndpoint + "/api/supplier";

  constructor(private http: HttpClient) { }

  get(id: number): Observable<Supplier> {
    return this.http.get<Supplier>(this.apiUrl + '?id=' + id);
  }

  getAll(): Observable<Supplier[]> {
    return this.http.get<Supplier[]>(this.apiUrl + "/GetAll");
  }

  getAllVMs(): Observable<Supplier[]> {
    return this.http.get<Supplier[]>(this.apiUrl + "/GetAllVMs");
  }

  create(supplier: Supplier) {
    return this.http.post<Supplier>(this.apiUrl + "/create", supplier);
  }

  update(supplier: Supplier) {
    return this.http.put<Supplier>(this.apiUrl + "/update", supplier);
  }

  delete(id: number) {
    return this.http.post<string>(this.apiUrl + "/delete?id=" + id, id);
  }

  getNew() {
    const supplier: Supplier = {
      id: 0,
      name: ''
    };
    return of(supplier);
  }
}
