import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable, of } from 'rxjs';
import { UnitOfMeasure } from '../../models/UnitOfMeasure';
import { environment } from '../../../environments/environment';

@Injectable({
  providedIn: 'root'
})
export class UnitofmeasureService {

  private apiUrl = environment.apiEndpoint + "/api/unitofmeasure";

  constructor(private http: HttpClient) { }

  get(id: number): Observable<UnitOfMeasure> {
    return this.http.get<UnitOfMeasure>(this.apiUrl + '?id=' + id);
  }

  getAll(): Observable<UnitOfMeasure[]> {
    return this.http.get<UnitOfMeasure[]>(this.apiUrl + "/GetAll");
  }

  getAllVMs(): Observable<UnitOfMeasure[]> {
    return this.http.get<UnitOfMeasure[]>(this.apiUrl + "/GetAllVMs");
  }

  create(unitofmeasure: UnitOfMeasure) {
    return this.http.post<UnitOfMeasure>(this.apiUrl + "/create", unitofmeasure);
  }

  update(unitofmeasure: UnitOfMeasure) {
    return this.http.put<UnitOfMeasure>(this.apiUrl + "/update", unitofmeasure);
  }

  delete(id: number) {
    return this.http.post<string>(this.apiUrl + "/delete?id=" + id, id);
  }

  getNew() {
    const unitofmeasure: UnitOfMeasure = {
      id: 0,
      name: '',
      description: ''
    };
    return of(unitofmeasure);
  }
}
