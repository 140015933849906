<nav class="nav-main">
  <a routerLink="/tags">Tags</a>
  <a routerLink="/admin" class="selected">Admin</a>
</nav>

<div style="padding:20px;">
  <a routerLink="/">Home</a> &gt; <a routerLink="/admin">Admin</a> &gt; <a routerLink="/admin/vessel">Vessels</a>
</div>

<ng-container *ngIf="vessels$ | async as vessels; else loading">

  <div>
    <p-card>
      <div class="p-card-title">
        Vessels
      </div>

      <div style="padding:20px 0px;">
        <button class="btn" routerLink="/admin/vessel/create" label="Create New Vessel" pButton icon="pi pi-plus"></button>
      </div>

      <div class="p-card-content">
        <p-table [value]="vessels">
          <ng-template pTemplate="caption">
            Vessels
          </ng-template>
          <ng-template pTemplate="header">
            <tr>
              <th>ID</th>
              <th>Name</th>
              <th>VesselCode</th>
              <th>Created</th>
              <th>Created By</th>
              <th></th>
            </tr>
          </ng-template>
          <ng-template pTemplate="body" let-vessel>
            <tr>
              <td>{{ vessel.id }}</td>
              <td>{{ vessel.name }}</td>
              <td>{{ vessel.vesselCode }}</td>
              <td>{{ vessel.created | date:'yyyy-MM-dd HH:mm' }}</td>
              <td>{{ vessel.createdBy }}</td>
              <td><a href="javascript:void(0)" (click)="edit(vessel)">edit</a></td>
            </tr>
          </ng-template>
        </p-table>
      </div>
    </p-card>
  </div>

</ng-container>
<ng-template #loading>
  Loading...
</ng-template>
