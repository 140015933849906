import { Component } from '@angular/core';
import { Router } from '@angular/router';
import { Observable } from 'rxjs/internal/Observable';
import { PLC } from '../../../models/PLC';
import { PlcService } from '../../../services/plc/plc.service';

@Component({
  selector: 'app-plc-list',
  templateUrl: './plc-list.component.html',
  styleUrls: ['./plc-list.component.css']
})
export class PlcListComponent {

  plcs$ = new Observable<PLC[]>();

  constructor(
    private router: Router,
    private plcService: PlcService
  ) { }

  ngOnInit(): void {
    this.plcs$ = this.plcService.getAll();
  }

  edit(plc: PLC) {
    this.router.navigateByUrl("/admin/plc/" + plc.id);
  }

}
