import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable, of } from 'rxjs';
import { SystemName } from '../../models/SystemName';
import { environment } from '../../../environments/environment';

@Injectable({
  providedIn: 'root'
})
export class SystemnameService {

  private apiUrl = environment.apiEndpoint + "/api/systemname";

  constructor(private http: HttpClient) { }

  get(id: number): Observable<SystemName> {
    return this.http.get<SystemName>(this.apiUrl + '?id=' + id);
  }

  getAll(): Observable<SystemName[]> {
    return this.http.get<SystemName[]>(this.apiUrl + "/GetAll");
  }

  getAllVMs(): Observable<SystemName[]> {
    return this.http.get<SystemName[]>(this.apiUrl + "/GetAllVMs");
  }

  create(systemName: SystemName) {
    return this.http.post<SystemName>(this.apiUrl + "/create", systemName);
  }

  update(systemName: SystemName) {
    return this.http.put<SystemName>(this.apiUrl + "/update", systemName);
  }

  delete(id: number) {
    return this.http.post<string>(this.apiUrl + "/delete?id=" + id, id);
  }

  getNew() {
    const systemName: SystemName = {
      id: 0,
      name: ''
    };
    return of(systemName);
  }
}
