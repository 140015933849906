import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable, of } from 'rxjs';
import { DataType } from '../../models/DataType';
import { environment } from '../../../environments/environment';


@Injectable({
  providedIn: 'root'
})
export class DatatypeService {

  private apiUrl = environment.apiEndpoint + "/api/datatype";

  constructor(private http: HttpClient) { }

  get(id: number): Observable<DataType> {
    return this.http.get<DataType>(this.apiUrl + '?id=' + id);
  }

  getAll(): Observable<DataType[]> {
    return this.http.get<DataType[]>(this.apiUrl + "/GetAll");
  }

  getAllVMs(): Observable<DataType[]> {
    return this.http.get<DataType[]>(this.apiUrl + "/GetAllVM");
  }

  create(datatype: DataType) {
    return this.http.post<DataType>(this.apiUrl + "/create", datatype);
  }

  update(datatype: DataType) {
    return this.http.put<DataType>(this.apiUrl + "/update", datatype);
  }

  delete(id: number) {
    return this.http.post<string>(this.apiUrl + "/delete?id=" + id, id);
  }

  getNew() {
    const datatype: DataType = {
      id: 0,
      name: ''
    };
    return of(datatype);
  }
}
