


<div style="padding:20px;">
  <a routerLink="/">Home</a> &nbsp; / &nbsp; <a routerLink="/tags/genids">Generate IDs</a>
</div>


<div class="card flex justify-content-center">


  <p-progressSpinner *ngIf="status.missingCount === -1"></p-progressSpinner>

  <p-card [style]="{ width: '360px' }"
          header="{{ status.missingCount === 0 ? 'All tags have IDs' : 'Some tags miss IDs' }}"
          subheader="{{status.missingCount}} tag{{ (status.missingCount > 1 ? 's' : '') }} {{ (status.missingCount > 1 ? 'are' : 'is') }}  missing Stream and Timeserie IDs."
          *ngIf="status.missingCount > -1">


    <p class="m-0">
      <span *ngIf="status.generating">
        Generating IDs...
        <p-progressBar mode="indeterminate" [style]="{ height: '6px' }"></p-progressBar>
      </span>
    </p>

    <div *ngIf="status.errors.length > 0" class="m-0" [style]="{'color': 'var(--red-500)' }">
      Some errors occured:
      <ul>
        <li *ngFor="let item of status.errors">{{ item.tag }}: {{item.error}} </li>
      </ul>
    </div>

    <ng-template pTemplate="footer">
      <p-button [disabled]="status.generating" *ngIf="status.missingCount > 0" label="Generate" icon="pi pi-tags" (click)="generate()"></p-button>
    </ng-template>
  </p-card>

</div>
