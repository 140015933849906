<nav class="nav-main">
  <a routerLink="/tags">Tags</a>
  <a routerLink="/admin" class="selected">Admin</a>
</nav>

<div style="padding:20px;">
  <a routerLink="/">Home</a> &nbsp; / &nbsp; <a routerLink="/admin">Admin</a> &nbsp; / &nbsp; <a routerLink="/datatype">Data Type</a>
</div>

<div style="padding:20px;">
  <ng-container *ngIf="datatype$ | async as dt; else loading">
    <form (ngSubmit)="onSubmit(dt)">

      <div class="form-control">
        <label for="dt.name">Name:</label>
        <input type="text" id="dt.name" name="dt.name" pInputText [(ngModel)]="dt.name" />
      </div>

      <div>
        <p-button type="submit" label="Save" icon="pi pi-save" iconPos="right" styleClass="p-button-raised" [disabled]="isSubmitDisabled"></p-button>
        <p-button type="button" label="Delete" icon="pi pi-trash" iconPos="right" (click)="onConfirmDelete(dt)" styleClass="p-button-raised p-button-danger" style="margin-left:10px;"></p-button>
      </div>

    </form>
  </ng-container>
  <ng-template #loading>
    Loading...
  </ng-template>
</div>
