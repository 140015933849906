<div class="header_bar">
  <div class="app_logo_title">
    <a routerLink="/">
      <div class="app_logo">
        <img src="/assets/images/vessel_60.png" alt="Logo for the Vessel Tag List application">
      </div>
      <div class="app_title">Vessel Tag List</div>
    </a>
  </div>
</div>
<div style="position:relative;z-index:9;">
  <router-outlet></router-outlet>
</div>
<div style="z-index:10;">
  <p-toast position="bottom-right"></p-toast>
  <p-confirmDialog></p-confirmDialog>
</div>
