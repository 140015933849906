import { Component } from '@angular/core';
import { Router } from '@angular/router';
import { Observable } from 'rxjs/internal/Observable';
import { UnitOfMeasure } from '../../../models/UnitOfMeasure';
import { UnitofmeasureService } from '../../../services/unitofmeasure/unitofmeasure.service';

@Component({
  selector: 'app-unitofmeasure-list',
  templateUrl: './unitofmeasure-list.component.html',
  styleUrls: ['./unitofmeasure-list.component.css']
})
export class UnitofmeasureListComponent {

  uoms$ = new Observable<UnitOfMeasure[]>();

  constructor(
    private router: Router,
    private unitofmeasureService: UnitofmeasureService
  ) { }

  ngOnInit(): void {
    this.uoms$ = this.unitofmeasureService.getAll();
  }

  edit(uom: UnitOfMeasure) {
    this.router.navigateByUrl("/admin/unitofmeasure/" + uom.id);
  }

}
