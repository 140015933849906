<nav class="nav-main">
  <a routerLink="/tags">Tags</a>
  <a routerLink="/admin" class="selected">Admin</a>
</nav>

<div style="padding:20px;">
  <a routerLink="/">Home</a> &nbsp; / &nbsp; <a routerLink="/admin">Admin</a> &nbsp; / &nbsp; <a routerLink="/admin/system">System</a>
</div>

<div style="padding:20px;">
  <ng-container *ngIf="systems$ | async as systems; else loading">

    <div>
      <p-card>
        <div class="p-card-title">
          System
        </div>

        <div style="padding:20px 0px;">
          <button class="btn" routerLink="/admin/system/create" label="Create New System" pButton icon="pi pi-plus"></button>
        </div>

        <div class="p-card-content">
          <p-table [value]="systems">
            <ng-template pTemplate="caption">
              System
            </ng-template>
            <ng-template pTemplate="header">
              <tr>
                <th>ID</th>
                <th>Name</th>
                <th>Created</th>
                <th>Created By</th>
                <th></th>
              </tr>
            </ng-template>
            <ng-template pTemplate="body" let-system>
              <tr>
                <td>{{ system.id }}</td>
                <td>{{ system.name }}</td>
                <td>{{ system.created | date:'yyyy-MM-dd HH:mm' }}</td>
                <td>{{ system.createdBy }}</td>
                <td><a href="javascript:void(0)" (click)="edit(system)">edit</a></td>
              </tr>
            </ng-template>
          </p-table>
        </div>
      </p-card>
    </div>

  </ng-container>
  <ng-template #loading>
    Loading...
  </ng-template>
</div>

