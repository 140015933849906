<nav class="nav-main">
  <a routerLink="/tags">Tags</a>
  <a routerLink="/admin" class="selected">Admin</a>
</nav>

<div style="padding:20px;">
  <a routerLink="/">Home</a> &nbsp; / &nbsp; <a routerLink="/admin">Admin</a> &nbsp; / &nbsp; <a routerLink="/admin/customattribute">Custom Attribute</a>
</div>

<div style="padding:20px;">
  <ng-container *ngIf="customattribute$ | async as customattribute; else loading">
    <form (ngSubmit)="onSubmit(customattribute)">

      <div class="form-control">
        <label for="customattribute.name">Name:</label>
        <input type="text" id="customattribute.name" name="customattribute.name" pInputText [(ngModel)]="customattribute.name" />
      </div>

      <div>
        <p-button type="submit" label="Save" icon="pi pi-save" iconPos="right" styleClass="p-button-raised" [disabled]="isSubmitDisabled"></p-button>
        <p-button *ngIf="!customattribute.isDeleted" type="button" label="Disable" icon="pi pi-pause-circle" iconPos="right" (click)="onConfirmDisable(customattribute)" styleClass="p-button-raised p-button-danger" style="margin-left:10px;"></p-button>
        <p-button *ngIf="customattribute.isDeleted" type="button" label="Enable" icon="pi pi-play-circle" iconPos="right" (click)="onEnable(customattribute)" styleClass="p-button-raised p-button-success" style="margin-left:10px;"></p-button>
      </div>

    </form>
  </ng-container>
  <ng-template #loading>
    Loading...
  </ng-template>
</div>
