import { Component, Input } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { Observable } from 'rxjs/internal/Observable';
import { first } from 'rxjs/operators';
import { UnitOfMeasure } from '../../../models/UnitOfMeasure';
import { UnitofmeasureService } from '../../../services/unitofmeasure/unitofmeasure.service';
import { ConfirmationService, MessageService } from 'primeng/api';

@Component({
  selector: 'app-unitofmeasure-edit',
  templateUrl: './unitofmeasure-edit.component.html',
  styleUrls: ['./unitofmeasure-edit.component.css']
})
export class UnitofmeasureEditComponent {

  @Input() id?: number;

  uom$ = new Observable<UnitOfMeasure>();

  isSubmitDisabled: boolean = false;

  constructor(
    private confirmationService: ConfirmationService,
    private messageService: MessageService,
    private router: Router,
    private route: ActivatedRoute,
    private unitofmeasureService: UnitofmeasureService
  ) { }

  ngOnInit(): void {

    this.route.params.subscribe(params => {
      this.id = params['id'];
      if (this.id === undefined) {
        // create new
        this.uom$ = this.unitofmeasureService.getNew();
      }
      else {
        // update existing
        this.uom$ = this.unitofmeasureService.get(this.id);
      }
    });

  }

  onSubmit(uom: UnitOfMeasure) {

    this.isSubmitDisabled = true;

    var errorFlag = false;

    if (uom.name == '') {
      errorFlag = true;
      this.messageService.add({ severity: 'error', summary: 'Required Field', detail: 'Name not entered' });
    }

    if (uom.description == '') {
      errorFlag = true;
      this.messageService.add({ severity: 'error', summary: 'Required Field', detail: 'Description not entered' });
    }

    if (errorFlag) {
      this.isSubmitDisabled = false;
    }
    else {

      if (uom.id == 0) {
        this.unitofmeasureService.create(uom).pipe(first()).subscribe(x => {
          this.router.navigateByUrl("/admin/unitofmeasure");
        });
      }
      else {
        this.unitofmeasureService.update(uom).pipe(first()).subscribe(x => {
          this.router.navigateByUrl("/admin/unitofmeasure");
        });
      }

    }
  }

  onConfirmDelete(uom: UnitOfMeasure) {
    this.confirmationService.confirm({
      message: 'Are you sure that you want to delete this UnitOfMeasure?',
      icon: 'pi pi-exclamation-triangle',
      accept: () => {
        this.onDelete(uom.id);
        this.confirmationService.close();
      },
      reject: () => {
        this.confirmationService.close();
      }
    });
  }

  onDelete(id: number) {
    this.unitofmeasureService.delete(id).pipe(first()).subscribe(
      x => {
        this.router.navigateByUrl("/admin/unitofmeasure");
      },
      error => {
        this.messageService.add({ severity: 'error', summary: 'Cannot delete', detail: error.error });
      }
    );
  }
}
