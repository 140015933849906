import { Component } from '@angular/core';
import { Router } from '@angular/router';
import { Observable } from 'rxjs';
import { CustomAttribute } from '../../../models/CustomAttribute';
import { CustomattributeService } from '../../../services/customattribute/customattribute.service';

@Component({
  selector: 'app-customattribute-list',
  templateUrl: './customattribute-list.component.html',
  styleUrls: ['./customattribute-list.component.css']
})
export class CustomattributeListComponent {

  customAttributes$ = new Observable<CustomAttribute[]>();

  constructor(
    private router: Router,
    private customattributeService: CustomattributeService
  ) { }

  ngOnInit(): void {
    this.customAttributes$ = this.customattributeService.getAll();
  }

  edit(customAttribute: CustomAttribute) {
    this.router.navigateByUrl("/admin/customattribute/" + customAttribute.id);
  }

}
