<nav class="nav-main">
  <a routerLink="/tags">Tags</a>
  <a routerLink="/admin" class="selected">Admin</a>
</nav>

<div style="padding:20px;">
  <a routerLink="/">Home</a> &nbsp; / &nbsp; <a routerLink="/admin">Admin</a> &nbsp; / &nbsp; <a routerLink="/admin/unitofmeasure">Unit of Measure</a>
</div>

<div style="padding:20px;">
  <ng-container *ngIf="uom$ | async as uom; else loading">
    <form (ngSubmit)="onSubmit(uom)">

      <div class="form-control">
        <label for="uom.name">Name:</label>
        <input type="text" id="uom.name" name="uom.name" pInputText [(ngModel)]="uom.name" />
      </div>

      <div class="form-control">
        <label for="uom.description">Description:</label>
        <input type="text" id="uom.description" name="uom.description" pInputText [(ngModel)]="uom.description" />
      </div>

      <div>
        <p-button type="submit" label="Save" icon="pi pi-save" iconPos="right" styleClass="p-button-raised" [disabled]="isSubmitDisabled"></p-button>
        <p-button type="button" label="Delete" icon="pi pi-trash" iconPos="right" (click)="onConfirmDelete(uom)" styleClass="p-button-raised p-button-danger" style="margin-left:10px;"></p-button>
      </div>

    </form>
  </ng-container>
  <ng-template #loading>
    Loading...
  </ng-template>
</div>
