import { Component } from '@angular/core';
import { Router } from '@angular/router';
import { Observable } from 'rxjs/internal/Observable';
import { Equipment } from '../../../models/Equipment';
import { EquipmentService } from '../../../services/equipment/equipment.service';

@Component({
  selector: 'app-equipment-list',
  templateUrl: './equipment-list.component.html',
  styleUrls: ['./equipment-list.component.css']
})
export class EquipmentListComponent {

  equipments$ = new Observable<Equipment[]>();

  constructor(
    private router: Router,
    private equipmentService: EquipmentService
  ) { }

  ngOnInit(): void {
    this.equipments$ = this.equipmentService.getAll();
  }

  edit(equipment: Equipment) {
    this.router.navigateByUrl("/admin/equipment/" + equipment.id);
  }

}
