<nav class="nav-main">
  <a routerLink="/tags">Tags</a>
  <a routerLink="/admin" class="selected">Admin</a>
</nav>

<div style="padding:20px;">
  <a routerLink="/">Home</a> &nbsp; / &nbsp; <a routerLink="/admin">Admin</a>
</div>

<div style="padding:20px;">
  <p-card>
    <div class="p-card-title">
      Admin
    </div>
    <div class="p-card-content">

      <p-table [value]="items">
        <ng-template pTemplate="header">
          <tr>
            <th>List</th>
          </tr>
        </ng-template>
        <ng-template pTemplate="body" let-item>
          <tr>
            <td><a routerLink="{{ item.url }}">{{ item.name }}</a></td>
          </tr>
        </ng-template>
      </p-table>

    </div>
  </p-card>
</div>
