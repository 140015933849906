import { Component, Input } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { Observable } from 'rxjs/internal/Observable';
import { first } from 'rxjs/internal/operators/first';
import { Vessel } from '../../../models/Vessel';
import { VesselService } from '../../../services/vessel/vessel.service';
import { ConfirmationService, MessageService } from 'primeng/api';

@Component({
  selector: 'app-vessel-edit',
  templateUrl: './vessel-edit.component.html',
  styleUrls: ['./vessel-edit.component.css']
})
export class VesselEditComponent {

  @Input() id?: number;
  vessel$ = new Observable<Vessel>();
  isSubmitDisabled: boolean = false;

  constructor(
    private confirmationService: ConfirmationService,
    private messageService: MessageService,
    private router: Router,
    private route: ActivatedRoute,
    private vesselService: VesselService
  ) { }

  ngOnInit(): void {

    this.route.params.subscribe(params => {
      this.id = params['id'];
      if (this.id === undefined) {
        // create a new user
        this.vessel$ = this.vesselService.getNew();
      }
      else {
        // edit an existing user
        this.vessel$ = this.vesselService.get(this.id);
      }
    });

  }

  onSubmit(vessel: Vessel) {

    this.isSubmitDisabled = true;

    var errorFlag = false;

    if (vessel.name == '') {
      errorFlag = true;
      this.messageService.add({ severity: 'error', summary: 'Required Field', detail: 'Name not entered' });
    }

    if (vessel.vesselCode == '') {
      errorFlag = true;
      this.messageService.add({ severity: 'error', summary: 'Required Field', detail: 'Vessel code not entered' });
    }

    if (errorFlag) {
      this.isSubmitDisabled = false;
    }
    else {

      if (vessel.id == 0) {
        this.vesselService.create(vessel).pipe(first()).subscribe(x => {
          this.router.navigateByUrl("/admin/vessel");
        });
      }
      else {
        this.vesselService.update(vessel).pipe(first()).subscribe(x => {
          this.router.navigateByUrl("/admin/vessel");
        });
      }

    }
  }

  onConfirmDelete(vessel: Vessel) {
    this.confirmationService.confirm({
      message: 'Are you sure that you want to delete this Vessel?',
      icon: 'pi pi-exclamation-triangle',
      accept: () => {
        this.onDelete(vessel.id);
        this.confirmationService.close();
      },
      reject: () => {
        this.confirmationService.close();
      }
    });
  }

  onDelete(id: number) {
    this.vesselService.delete(id).pipe(first()).subscribe(
      x => {
        this.router.navigateByUrl("/admin/vessel");
      },
      error => {
        this.messageService.add({ severity: 'error', summary: 'Cannot delete', detail: error.error });
      }
    );
  }
}
