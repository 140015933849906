<nav class="nav-main">
  <a routerLink="/tags">Tags</a>
  <a routerLink="/admin" class="selected">Admin</a>
</nav>

<div style="padding:20px;">
  <a routerLink="/">Home</a> &nbsp; / &nbsp; <a routerLink="/admin">Admin</a> &nbsp; / &nbsp; <a routerLink="/admin/plc">PLC</a>
</div>

<div style="padding:20px;">
  <ng-container *ngIf="plcs$ | async as plcs; else loading">

    <div>
      <p-card>
        <div class="p-card-title">
          PLC
        </div>

        <div style="padding:20px 0px;">
          <button class="btn" routerLink="/admin/plc/create" label="Create New PLC" pButton icon="pi pi-plus"></button>
        </div>

        <div class="p-card-content">
          <p-table [value]="plcs">
            <ng-template pTemplate="caption">
              PLC
            </ng-template>
            <ng-template pTemplate="header">
              <tr>
                <th>ID</th>
                <th>Name</th>
                <th>Created</th>
                <th>Created By</th>
                <th></th>
              </tr>
            </ng-template>
            <ng-template pTemplate="body" let-plc>
              <tr>
                <td>{{ plc.id }}</td>
                <td>{{ plc.name }}</td>
                <td>{{ plc.created | date:'yyyy-MM-dd HH:mm' }}</td>
                <td>{{ plc.createdBy }}</td>
                <td><a href="javascript:void(0)" (click)="edit(plc)">edit</a></td>
              </tr>
            </ng-template>
          </p-table>
        </div>
      </p-card>
    </div>

  </ng-container>
  <ng-template #loading>
    Loading...
  </ng-template>
</div>

