import { Component } from '@angular/core';
import { Router } from '@angular/router';
import { Observable } from 'rxjs/internal/Observable';
import { Supplier } from '../../../models/Supplier';
import { SupplierService } from '../../../services/supplier/supplier.service';

@Component({
  selector: 'app-supplier-list',
  templateUrl: './supplier-list.component.html',
  styleUrls: ['./supplier-list.component.css']
})
export class SupplierListComponent {

  suppliers$ = new Observable<Supplier[]>();

  constructor(
    private router: Router,
    private supplierService: SupplierService
  ) { }

  ngOnInit(): void {
    this.suppliers$ = this.supplierService.getAll();
  }

  edit(supplier: Supplier) {
    this.router.navigateByUrl("/admin/supplier/" + supplier.id);
  }

}
