import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable, of } from 'rxjs';
import { Vessel } from '../../models/Vessel';
import { environment } from '../../../environments/environment';

@Injectable({
  providedIn: 'root'
})
export class VesselService {

  private apiUrl = environment.apiEndpoint + "/api/vessel";

  constructor(private http: HttpClient) { }

  get(id: number): Observable<Vessel> {
    return this.http.get<Vessel>(this.apiUrl + '?id=' + id);
  }

  getAll(): Observable<Vessel[]> {
    return this.http.get<Vessel[]>(this.apiUrl + "/GetAll");
  }

  getAllVMs(): Observable<Vessel[]> {
    return this.http.get<Vessel[]>(this.apiUrl + "/GetAllVMs");
  }

  create(vessel: Vessel) {
    return this.http.post<Vessel>(this.apiUrl + "/create", vessel);
  }

  update(vessel: Vessel) {
    return this.http.put<Vessel>(this.apiUrl + "/update", vessel);
  }

  delete(id: number) {
    return this.http.post<string>(this.apiUrl + "/delete?id=" + id, id);
  }

  getNew() {
    const vessel: Vessel = {
      id: 0,
      name: '',
      vesselCode: ''
    };
    return of(vessel);
  }
}
