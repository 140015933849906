import { Component } from '@angular/core';
import { Router } from '@angular/router';
import { Observable } from 'rxjs/internal/Observable';
import { DataType } from '../../../models/DataType';
import { DatatypeService } from '../../../services/datatype/datatype.service';

@Component({
  selector: 'app-datatype-list',
  templateUrl: './datatype-list.component.html',
  styleUrls: ['./datatype-list.component.css']
})
export class DatatypeListComponent {

  datatypes$ = new Observable<DataType[]>();

  constructor(
    private router: Router,
    private datatypeService: DatatypeService
  ) { }

  ngOnInit(): void {
    this.datatypes$ = this.datatypeService.getAll();
  }

  edit(datatype: DataType) {
    this.router.navigateByUrl("/admin/datatype/" + datatype.id);
  }

}
