import { Component } from '@angular/core';
import { Router } from '@angular/router';
import { Observable } from 'rxjs/internal/Observable';
import { System } from '../../../models/System';
import { SystemService } from '../../../services/system/system.service';

@Component({
  selector: 'app-system-list',
  templateUrl: './system-list.component.html',
  styleUrls: ['./system-list.component.css']
})
export class SystemListComponent {

  systems$ = new Observable<System[]>();

  constructor(
    private router: Router,
    private systemService: SystemService
  ) { }

  ngOnInit(): void {
    this.systems$ = this.systemService.getAll();
  }

  edit(system: System) {
    this.router.navigateByUrl("/admin/system/" + system.id);
  }

}
