import { Component, Input } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { Observable } from 'rxjs/internal/Observable';
import { first } from 'rxjs/operators';
import { Equipment } from '../../../models/Equipment';
import { EquipmentService } from '../../../services/equipment/equipment.service';
import { SubgroupService } from '../../../services/subgroup/subgroup.service';
import { MessageService } from 'primeng/api';
import { SubGroup } from '../../../models/SubGroup';

@Component({
  selector: 'app-equipment-edit',
  templateUrl: './equipment-edit.component.html',
  styleUrls: ['./equipment-edit.component.css']
})
export class EquipmentEditComponent {

  @Input() id?: number;
  isEdit: Boolean = false;
  equipment$ = new Observable<Equipment>();
  subgroup$ = new Observable<SubGroup>();

  isSubmitDisabled: boolean = false;

  constructor(
    private messageService: MessageService,
    private router: Router,
    private route: ActivatedRoute,
    private equipmentService: EquipmentService,
    private subgroupService: SubgroupService
  ) { }

  ngOnInit(): void {

    this.route.params.subscribe(params => {
      this.id = params['id'];
      if (this.id === undefined) {
        // create new
        this.equipment$ = this.equipmentService.getNew();
      }
      else {
        // update existing
        this.equipment$ = this.equipmentService.get(this.id);
        this.subgroup$ = this.subgroupService.getSubGroupForEquipment(this.id);
        this.isEdit = true;
      }
    });

  }

  onSubmit(equipment: Equipment) {

    this.isSubmitDisabled = true;

    var errorFlag = false;

    if (!equipment.id) {
      errorFlag = true;
      this.messageService.add({ severity: 'error', summary: 'Required Field', detail: 'ID not entered' });
    }

    if (equipment.name == '') {
      errorFlag = true;
      this.messageService.add({ severity: 'error', summary: 'Required Field', detail: 'Name not entered' });
    }

    if (errorFlag) {
      this.isSubmitDisabled = false;
    }
    else {
      this.equipmentService.update(equipment).pipe(first()).subscribe(
        res => {
          this.router.navigateByUrl("/admin/equipment");
        },
        err => {
          if (err.error) {
            this.messageService.add({ severity: 'error', summary: 'Missing Parent', detail: err.error });
          }        
        }
      );
    }

  }

  subgroup_edit(subgroup: SubGroup) {
    this.router.navigateByUrl("/admin/subgroup/" + subgroup.id);
  }

}
