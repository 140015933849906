import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable, of } from 'rxjs';
import { EquipmentType } from '../../models/EquipmentType';
import { environment } from '../../../environments/environment';

@Injectable({
  providedIn: 'root'
})
export class EquipmenttypeService {

  private apiUrl = environment.apiEndpoint + "/api/equipmenttype";

  constructor(private http: HttpClient) { }

  get(id: number): Observable<EquipmentType> {
    return this.http.get<EquipmentType>(this.apiUrl + '?id=' + id);
  }

  getAll(): Observable<EquipmentType[]> {
    return this.http.get<EquipmentType[]>(this.apiUrl + "/GetAll");
  }

  getAllVMs(): Observable<EquipmentType[]> {
    return this.http.get<EquipmentType[]>(this.apiUrl + "/GetAllVMs");
  }

  create(equipmenttype: EquipmentType) {
    return this.http.post<EquipmentType>(this.apiUrl + "/create", equipmenttype);
  }

  update(equipmenttype: EquipmentType) {
    return this.http.put<EquipmentType>(this.apiUrl + "/update", equipmenttype);
  }

  delete(id: number) {
    return this.http.post<string>(this.apiUrl + "/delete?id=" + id, id);
  }

  getNew() {
    const equipmenttype: EquipmentType = {
      id: 0,
      name: '',
      rangeStart: 0,
      rangeEnd: 0
    };
    return of(equipmenttype);
  }
}
