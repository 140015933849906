import { Component } from '@angular/core';

@Component({
  selector: 'app-admin-list',
  templateUrl: './admin-list.component.html',
  styleUrls: ['./admin-list.component.css']
})
export class AdminListComponent {

  items: any[] = [
    { name: "Custom Attribute", url: "/admin/customattribute" },
    { name: "Data Type", url: "/admin/datatype" },
    { name: "Equipment", url: "/admin/equipment" },
    { name: "Equipment Type", url: "/admin/equipmenttype" },
    { name: "PLC", url: "/admin/plc" },
    { name: "Sub Group", url: "/admin/subgroup" },
    { name: "Supplier", url: "/admin/supplier" },
    { name: "System", url: "/admin/system" },
    { name: "System Name", url: "/admin/systemname" },
    { name: "Unit of Measure", url: "/admin/unitofmeasure" },
    { name: "Vessel", url: "/admin/vessel" }
  ];


}
