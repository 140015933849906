<nav class="nav-main">
  <a routerLink="/tags">Tags</a>
  <a routerLink="/admin" class="selected">Admin</a>
</nav>

<div style="padding:20px;">
  <a routerLink="/">Home</a> &nbsp; / &nbsp; <a routerLink="/admin">Admin</a> &nbsp; / &nbsp; <a routerLink="/admin/system">System</a>
</div>

<div style="padding:20px;">

  <ng-container *ngIf="system$ | async as system; else loading">
    <p-card>
      <div class="p-card-title">
        System
      </div>
      <div class="p-card-content">
        <form (ngSubmit)="onSubmit(system)">

          <div class="form-control">
            <label for="system.id">ID:</label>
            <input type="text" id="system.id" name="system.id" pInputText [(ngModel)]="system.id" [readonly]="isEdit" />
          </div>

          <div class="form-control">
            <label for="system.name">Name:</label>
            <input type="text" id="system.name" name="system.name" pInputText [(ngModel)]="system.name" />
          </div>

          <div>
            <button type="submit" label="Save" icon="pi pi-save" iconPos="right" pButton [disabled]="isSubmitDisabled"></button>
          </div>

        </form>
      </div>
    </p-card>

    <div style="margin-top:20px;">
      <p-card>
        <div class="p-card-title">
          Sub Groups
        </div>
        <div class="p-card-content">
          <ng-container *ngIf="subgroups$ | async as subgroups;">
            <p-table [value]="subgroups">
              <ng-template pTemplate="header">
                <tr>
                  <th>ID</th>
                  <th>SubGroup</th>
                </tr>
              </ng-template>
              <ng-template pTemplate="body" let-subgroup>
                <tr>
                  <td><a href="javascript:void(0)" (click)="subgroup_edit(subgroup)">{{subgroup.id}}</a></td>
                  <td><a href="javascript:void(0)" (click)="subgroup_edit(subgroup)">{{subgroup.name}}</a></td>
                </tr>
              </ng-template>
            </p-table>
          </ng-container>
        </div>
      </p-card>          
    </div>

  </ng-container>
  <ng-template #loading>
    Loading...
  </ng-template>
</div>
