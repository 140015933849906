import { Component } from '@angular/core';
import { Router } from '@angular/router';
import { Observable } from 'rxjs/internal/Observable';
import { EquipmentType } from '../../../models/EquipmentType';
import { EquipmenttypeService } from '../../../services/equipmenttype/equipmenttype.service';

@Component({
  selector: 'app-equipmenttype-list',
  templateUrl: './equipmenttype-list.component.html',
  styleUrls: ['./equipmenttype-list.component.css']
})
export class EquipmenttypeListComponent {

  equipmenttypes$ = new Observable<EquipmentType[]>();

  constructor(
    private router: Router,
    private equipmenttypeService: EquipmenttypeService
  ) { }

  ngOnInit(): void {
    this.equipmenttypes$ = this.equipmenttypeService.getAll();
  }

  edit(equipmenttype: EquipmentType) {
    this.router.navigateByUrl("/admin/equipmenttype/" + equipmenttype.id);
  }

}
