<nav class="nav-main">
  <a routerLink="/tags" class="selected">Tags</a>
  <a routerLink="/admin">Admin</a>
</nav>

<div style="padding:20px;">
  <a routerLink="/">Home</a> &nbsp; / &nbsp; <a routerLink="/tags/upload">Excel Upload</a>
</div>

<div style="padding:20px;">
  <form (ngSubmit)="onSubmit()">

    <div style="margin-bottom:20px;">
      Upload an Excel file with a list of tags, the expected format is:<br />
      <table class="columns_table">
        <tr>
          <td>Column B</td>
          <td>Vessel name</td>
        </tr>
        <tr>
          <td>Column C</td>
          <td>Equipment code (e.g. 914)</td>
        </tr>
        <tr>
          <td>Column D</td>
          <td>Sequence number</td>
        </tr>
        <tr>
          <td>Column E</td>
          <td>Supplier name</td>
        </tr>
        <tr>
          <td>Column F</td>
          <td>Systemname name</td>
        </tr>
        <tr>
          <td>Column G</td>
          <td>Supplier tag</td>
        </tr>
        <tr>
          <td>Column H</td>
          <td>Description</td>
        </tr>
        <tr>
          <td>Column I</td>
          <td>Data type name</td>
        </tr>
        <tr>
          <td>Column J</td>
          <td>Unit of measure name</td>
        </tr>
        <tr>
          <td>Column L</td>
          <td>Address</td>
        </tr>
        <tr>
          <td>Column M</td>
          <td>PLC name</td>
        </tr>
        <tr>
          <td>Column O</td>
          <td>Sampling rate</td>
        </tr>
        <tr>
          <td>Column Q</td>
          <td>Stream ID (not required)</td>
        </tr>
        <tr>
          <td>Column R</td>
          <td>Time Series ID (not required)</td>
        </tr>
        <tr>
          <td>Column S</td>
          <td>Vessel code (e.g. SRI)</td>
        </tr>
        <tr>
          <td>Column W</td>
          <td>System name</td>
        </tr>
        <tr>
          <td>Column X</td>
          <td>Sub group name</td>
        </tr>
        <tr>
          <td>Column Y</td>
          <td>Equipment name</td>
        </tr>
      </table>
    </div>


    <div class="form-control">
      <label for="filename">Excel file to upload (.xlsx)</label>
      <div>
        <div style="display:inline-block;">
          <div style="display:table-cell;vertical-align:middle;">
            <p-fileUpload #fileInput name="filename" mode="basic" [auto]="true"
                          id="filename"
                          [customUpload]="true"
                          (uploadHandler)="getExcelTabNames($event)"
                          accept="{{acceptedFiles}}"
                          [ngStyle]="{'display': (filename==='' ? '' : 'none')}">
            </p-fileUpload>
            <button label="" icon="pi pi-times" iconPos="left" (click)="clearSelectedFile()" pButton *ngIf="filename!==''"></button>
          </div>
          <div style="display:table-cell;vertical-align:middle;">
            <span style="display: inline-block; margin-left: 10px; border: solid 1px #CED4DA; padding: 9px; border-radius: 6px; font-size: 1rem; min-width: 250px; height: 38px;">{{ filename }}</span>
          </div>
        </div>
      </div>
    </div>

    <div class="form-control" *ngIf="filename !== ''">
      <label for="tabName">Tab name <span class="red bold">*</span>:</label>
      <p-dropdown [options]="tabNames" [(ngModel)]="tabName" name="tabName" id="tabName"></p-dropdown>
    </div>

    <div class="form-control" *ngIf="filename !== ''">
      <button label="Verify" type="button" icon="pi pi-check" iconPos="left" (click)="verifyFile()" pButton></button>
    </div>

    <div class="form-control" *ngIf="filename !== ''">
      <table class="columns_table">
        <tr *ngFor="let verifyError of verifyErrors">
          <td>{{ verifyError }}</td>
        </tr>
      </table>
    </div>

    <div class="form-control" *ngIf="isVerified">
      <button type="submit" label="Upload" icon="pi pi-upload" iconPos="left" pButton></button>
    </div>

  </form>
</div>

<div class="loading" *ngIf="showLoading">
  <div class="loading-spinner">
    <p-progressSpinner></p-progressSpinner>
  </div>
</div>
