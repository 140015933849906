<nav class="nav-main">
  <a routerLink="/tags">Tags</a>
  <a routerLink="/admin" class="selected">Admin</a>
</nav>

<div style="padding:20px;">
  <a routerLink="/">Home</a> &nbsp; / &nbsp; <a routerLink="/admin">Admin</a> &nbsp; / &nbsp; <a routerLink="/datatype">Data Type</a>
</div>

<div style="padding:20px;">
  <ng-container *ngIf="datatypes$ | async as datatypes; else loading">
    <div>
      <p-card>
        <div class="p-card-title">
          Data Types
        </div>

        <div style="padding:20px 0px;">
          <button class="btn" routerLink="/admin/datatype/create" label="Create New Data Type" pButton icon="pi pi-plus"></button>
        </div>

        <div class="p-card-content">
          <p-table [value]="datatypes">
            <ng-template pTemplate="caption">
              Data Types
            </ng-template>
            <ng-template pTemplate="header">
              <tr>
                <th>ID</th>
                <th>Name</th>
                <th>Created</th>
                <th>Created By</th>
                <th></th>
              </tr>
            </ng-template>
            <ng-template pTemplate="body" let-datatype>
              <tr>
                <td>{{ datatype.id }}</td>
                <td>{{ datatype.name }}</td>
                <td>{{ datatype.created | date:'yyyy-MM-dd HH:mm' }}</td>
                <td>{{ datatype.createdBy }}</td>
                <td><a href="javascript:void(0)" (click)="edit(datatype)">edit</a></td>
              </tr>
            </ng-template>
          </p-table>
        </div>
      </p-card>
    </div>
  </ng-container>
  <ng-template #loading>
    Loading...
  </ng-template>
</div>
