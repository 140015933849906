import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable, of } from 'rxjs';
import { environment } from '../../../environments/environment';
import { CustomAttribute } from '../../models/CustomAttribute';

@Injectable({
  providedIn: 'root'
})
export class CustomattributeService {

  private apiUrl = environment.apiEndpoint + "/api/customattribute";

  constructor(private http: HttpClient) { }

  get(id: number): Observable<CustomAttribute> {
    return this.http.get<CustomAttribute>(this.apiUrl + '?id=' + id);
  }

  getAll(): Observable<CustomAttribute[]> {
    return this.http.get<CustomAttribute[]>(this.apiUrl + "/GetAll");
  }

  getAllVMs(): Observable<CustomAttribute[]> {
    return this.http.get<CustomAttribute[]>(this.apiUrl + "/GetAllVMs");
  }

  create(customattribute: CustomAttribute) {
    return this.http.post<CustomAttribute>(this.apiUrl + "/create", customattribute);
  }

  update(customattribute: CustomAttribute) {
    return this.http.put<CustomAttribute>(this.apiUrl + "/update", customattribute);
  }

  delete(id: number) {
    return this.http.post<string>(this.apiUrl + "/delete?id=" + id, id);
  }

  enable(id: number) {
    return this.http.post<string>(this.apiUrl + '/enable?id=' + id, id);
  }

  getNew() {
    const customattribute: CustomAttribute = {
      id: 0,
      name: '',
      isDeleted: false
    };
    return of(customattribute);
  }
}
