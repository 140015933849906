import { Component, ViewChild } from '@angular/core';
import { Router } from '@angular/router';
import { FileUpload } from 'primeng/fileupload';
import { first } from 'rxjs/internal/operators/first';
import { TagService } from '../../../services/tag/tag.service';

@Component({
  selector: 'app-tag-excel-upload',
  templateUrl: './tag-excel-upload.component.html',
  styleUrls: ['./tag-excel-upload.component.css']
})
export class TagExcelUploadComponent {

  @ViewChild('fileInput') fileInput!: FileUpload;

  acceptedFiles: string = ".xls, .xlsx";
  filename: string = "";
  tabName: string = "";
  tabNames!: string[];
  verifyErrors!: string[];
  isClockBytes: boolean = false;

  showLoading: boolean = false;

  isVerified: boolean = false;


  constructor(
    private router: Router,
    private tagService: TagService
  ) { }

  ngOnInit(): void {

  }

  clearSelectedFile() {
    this.fileInput.clear();
    this.filename = "";
    this.tabName = "";
    this.tabNames = [];
    this.isVerified = false;
  }



  getExcelTabNames(event: any) {
    this.verifyErrors = [];
    this.showLoading = true;
    var file = this.fileInput.files[0];
    this.filename = file.name;
    this.tagService.getExcelTabNames(file).pipe(first()).subscribe(
      x => {
        this.tabNames = x;
        this.showLoading = false;
      },
      err => {
        this.showLoading = false;
        window.alert(err.error);
      }
    );
  }

  verifyFile() {
    this.verifyErrors = [];
    this.showLoading = true;
    this.tagService.verify(this.fileInput.files[0], this.tabName).pipe(first()).subscribe(
      x => {
        this.showLoading = false;
        this.verifyErrors = x;
        this.isVerified = true;
      },
      err => {
        this.showLoading = false;
        window.alert(err.error);
      }
    );
  }

  onSubmit() {
    this.showLoading = true;
    this.tagService.uploadFile(this.fileInput.files[0], this.tabName).pipe(first()).subscribe(
      x => {
        this.showLoading = false;
        this.router.navigate(['/']);
      },
      err => {        
        this.showLoading = false;
        window.alert(err.error);
      }
    );
  }

}
