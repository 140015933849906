import { Component } from '@angular/core';
import { Router } from '@angular/router';
import { Observable } from 'rxjs/internal/Observable';
import { SubGroup } from '../../../models/SubGroup';
import { SubgroupService } from '../../../services/subgroup/subgroup.service';

@Component({
  selector: 'app-subgroup-list',
  templateUrl: './subgroup-list.component.html',
  styleUrls: ['./subgroup-list.component.css']
})
export class SubgroupListComponent {

  subgroups$ = new Observable<SubGroup[]>();

  constructor(
    private router: Router,
    private subgroupService: SubgroupService
  ) { }

  ngOnInit(): void {
    this.subgroups$ = this.subgroupService.getAll();
  }

  edit(subgroup: SubGroup) {
    this.router.navigateByUrl("/admin/subgroup/" + subgroup.id);
  }

}
