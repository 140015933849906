import { Component } from '@angular/core';
import { Router } from '@angular/router';
import { Observable } from 'rxjs/internal/Observable';
import { Vessel } from '../../../models/Vessel';
import { VesselService } from '../../../services/vessel/vessel.service';

@Component({
  selector: 'app-vessel-list',
  templateUrl: './vessel-list.component.html',
  styleUrls: ['./vessel-list.component.css']
})
export class VesselListComponent {

  vessels$ = new Observable<Vessel[]>();

  constructor(
    private router: Router,
    private vesselService: VesselService
  ) { }

  ngOnInit(): void {
    this.vessels$ = this.vesselService.getAll();
  }

  edit(vessel: Vessel) {
    this.router.navigateByUrl("/admin/vessel/" + vessel.id);
  }
}
