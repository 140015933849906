import { Component, Input } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { Observable } from 'rxjs/internal/Observable';
import { first } from 'rxjs/operators';
import { ConfirmationService, MessageService } from 'primeng/api';
import { CustomAttribute } from '../../../models/CustomAttribute';
import { CustomattributeService } from '../../../services/customattribute/customattribute.service';

@Component({
  selector: 'app-customattribute-edit',
  templateUrl: './customattribute-edit.component.html',
  styleUrls: ['./customattribute-edit.component.css']
})
export class CustomattributeEditComponent {

  @Input() id?: number;

  customattribute$ = new Observable<CustomAttribute>();

  isSubmitDisabled: boolean = false;

  constructor(
    private confirmationService: ConfirmationService,
    private messageService: MessageService,
    private router: Router,
    private route: ActivatedRoute,
    private customattributeService: CustomattributeService
  ) { }

  ngOnInit(): void {

    this.route.params.subscribe(params => {
      this.id = params['id'];
      if (this.id === undefined) {
        // create new
        this.customattribute$ = this.customattributeService.getNew();
      }
      else {
        // update existing
        this.customattribute$ = this.customattributeService.get(this.id);
      }
    });

  }

  onSubmit(customattribute: CustomAttribute) {

    this.isSubmitDisabled = true;

    var errorFlag = false;

    if (customattribute.name == '') {
      errorFlag = true;
      this.messageService.add({ severity: 'error', summary: 'Required Field', detail: 'Name not entered' });
    }

    if (errorFlag) {
      this.isSubmitDisabled = false;
    }
    else {

      if (customattribute.id == 0) {
        this.customattributeService.create(customattribute).pipe(first()).subscribe(x => {
          this.router.navigateByUrl("/admin/customattribute");
        });
      }
      else {
        this.customattributeService.update(customattribute).pipe(first()).subscribe(x => {
          this.router.navigateByUrl("/admin/customattribute");
        });
      }

    }
  }

  onConfirmDisable(customattribute: CustomAttribute) {
    this.confirmationService.confirm({
      message: 'Are you sure that you want to disable this CustomAttribute?',
      icon: 'pi pi-exclamation-triangle',
      accept: () => {
        this.onDisable(customattribute.id);
        this.confirmationService.close();
      },
      reject: () => {
        this.confirmationService.close();
      }
    });
  }

  onDisable(id: number) {
    this.customattributeService.delete(id).pipe(first()).subscribe(
      x => {
        this.router.navigateByUrl("/admin/customattribute");
      },
      error => {
        this.messageService.add({ severity: 'error', summary: 'Cannot disable', detail: error.error });
      }
    );
  }

  onEnable(customattribute: CustomAttribute) {
    this.customattributeService.enable(customattribute.id).pipe(first()).subscribe(
      x => {
        this.router.navigateByUrl("/admin/customattribute");
      },
      error => {
        this.messageService.add({ severity: 'error', summary: 'Cannot enable', detail: error.error });
      }
    );
  }

}
