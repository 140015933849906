<nav class="nav-main">
  <a routerLink="/tags">Tags</a>
  <a routerLink="/admin" class="selected">Admin</a>
</nav>

<div style="padding:20px;">
  <a routerLink="/">Home</a> &nbsp; / &nbsp; <a routerLink="/admin">Admin</a> &nbsp; / &nbsp; <a routerLink="/admin/equipment">Equipment</a>
</div>

<div style="padding:20px;">

  <ng-container *ngIf="equipment$ | async as equipment; else loading">

    <p-card>
      <div class="p-card-title">
        Equipment
      </div>
      <div class="p-card-content">
        <form (ngSubmit)="onSubmit(equipment)">

          <div class="form-control">
            <label for="equipment.id">ID:</label>
            <input type="text" id="equipment.id" name="equipment.id" pInputText [(ngModel)]="equipment.id" [readonly]="isEdit" />
          </div>

          <div class="form-control">
            <label for="equipment.name">Name:</label>
            <input type="text" id="equipment.name" name="equipment.name" pInputText [(ngModel)]="equipment.name" />
          </div>

          <div>
            <button type="submit" label="Save" icon="pi pi-save" iconPos="right" pButton [disabled]="isSubmitDisabled"></button>
          </div>

        </form>
      </div>
    </p-card>

    <div style="margin-top:20px;">
      <p-card>
        <div class="p-card-title">
          Sub Group
        </div>
        <div class="p-card-content">
          <ng-container *ngIf="subgroup$ | async as subgroup;">
            <p-table [value]="[subgroup]">
              <ng-template pTemplate="header">
                <tr>
                  <th>ID</th>
                  <th>Sub Group</th>
                </tr>
              </ng-template>
              <ng-template pTemplate="body" let-s>
                <tr>
                  <td><a href="javascript:void(0)" (click)="subgroup_edit(s)">{{s.id}}</a></td>
                  <td><a href="javascript:void(0)" (click)="subgroup_edit(s)">{{s.name}}</a></td>
                </tr>
              </ng-template>
            </p-table>
          </ng-container>
        </div>
      </p-card>
    </div>

  </ng-container>
  <ng-template #loading>
    Loading...
  </ng-template>
</div>
