import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable, of } from 'rxjs';
import { PLC } from '../../models/PLC';
import { environment } from '../../../environments/environment';

@Injectable({
  providedIn: 'root'
})
export class PlcService {

  private apiUrl = environment.apiEndpoint + "/api/plc";

  constructor(private http: HttpClient) { }

  get(id: number): Observable<PLC> {
    return this.http.get<PLC>(this.apiUrl + '?id=' + id);
  }

  getAll(): Observable<PLC[]> {
    return this.http.get<PLC[]>(this.apiUrl + "/GetAll");
  }

  getAllVMs(): Observable<PLC[]> {
    return this.http.get<PLC[]>(this.apiUrl + "/GetAllVMs");
  }

  create(plc: PLC) {
    return this.http.post<PLC>(this.apiUrl + "/create", plc);
  }

  update(plc: PLC) {
    return this.http.put<PLC>(this.apiUrl + "/update", plc);
  }

  delete(id: number) {
    return this.http.post<string>(this.apiUrl + "/delete?id=" + id, id);
  }

  getNew() {
    const plc: PLC = {
      id: 0,
      name: ''
    };
    return of(plc);
  }
}
