<nav class="nav-main">
  <a routerLink="/tags" class="selected">Tags</a>
  <a routerLink="/admin">Admin</a>
</nav>

<div style="padding:20px;">

  <!--<ng-container *ngIf="tags$ | async as tags; else loading">-->
  <ng-container>

    <p-table [value]="tags"
             [lazy]="true"
             (onLazyLoad)="loadTags($event)"
             [paginator]="true"
             [totalRecords]="totalRecords"
             [rows]="20"
             [showCurrentPageReport]="true"
             [tableStyle]="{'min-width': '50rem'}"
             currentPageReportTemplate="Showing {first} to {last} of {totalRecords} entries"
             [rowsPerPageOptions]="[10,20,30,40,50]"
             [globalFilterFields]="['s7Tag', 'vessel.name','equipmentType.name','system.name','subGroup.name']"
             [(selection)]="selectedTags" dataKey="id" [selectionPageOnly]="true" >

      <ng-template pTemplate="header">
        <tr>
          <th style="width:30px;">
            <p-tableHeaderCheckbox></p-tableHeaderCheckbox>
          </th>
          <th pSortableColumn="S7Tag" style="white-space:nowrap;">S7Tag <p-sortIcon></p-sortIcon><p-columnFilter type="text" field="S7Tag" display="menu"></p-columnFilter></th>
          <th pSortableColumn="Vessel_Name" style="white-space:nowrap;">
            Vessel <p-sortIcon></p-sortIcon>
            <p-columnFilter field="Vessel_Name" matchMode="in" display="menu" [showMatchModes]="false" [showOperator]="false" [showAddButton]="false">
              <ng-template pTemplate="filter" let-value let-filter="filterCallback">
                <p-multiSelect [ngModel]="value" [options]="vessels" placeholder="Any" (onChange)="filter($event.value)">
                  <ng-template let-option pTemplate="item">{{option}}</ng-template>
                </p-multiSelect>
              </ng-template>
            </p-columnFilter>
          </th>
          <th pSortableColumn="EquipmentType_Name" style="white-space:nowrap;">
            Equipment Type <p-sortIcon></p-sortIcon>
            <p-columnFilter field="EquipmentType_Name" matchMode="in" display="menu" [showMatchModes]="false" [showOperator]="false" [showAddButton]="false">
              <ng-template pTemplate="filter" let-value let-filter="filterCallback">
                <p-multiSelect [ngModel]="value" [options]="equipmentTypes" placeholder="Any" (onChange)="filter($event.value)">
                  <ng-template let-option pTemplate="item">{{option}}</ng-template>
                </p-multiSelect>
              </ng-template>
            </p-columnFilter>
          </th>
          <th pSortableColumn="System_Name" style="white-space:nowrap;">
            System <p-sortIcon></p-sortIcon>
            <p-columnFilter field="System_Name" matchMode="in" display="menu" [showMatchModes]="false" [showOperator]="false" [showAddButton]="false">
              <ng-template pTemplate="filter" let-value let-filter="filterCallback">
                <p-multiSelect [ngModel]="value" [options]="systems" placeholder="Any" (onChange)="filter($event.value)">
                  <ng-template let-option pTemplate="item">{{option}}</ng-template>
                </p-multiSelect>
              </ng-template>
            </p-columnFilter>
          </th>
          <th pSortableColumn="SubGroup_Name" style="white-space:nowrap;">
            Sub Group <p-sortIcon></p-sortIcon>
            <p-columnFilter field="SubGroup_Name" matchMode="in" display="menu" [showMatchModes]="false" [showOperator]="false" [showAddButton]="false">
              <ng-template pTemplate="filter" let-value let-filter="filterCallback">
                <p-multiSelect [ngModel]="value" [options]="subGroups" placeholder="Any" (onChange)="filter($event.value)">
                  <ng-template let-option pTemplate="item">{{option}}</ng-template>
                </p-multiSelect>
              </ng-template>
            </p-columnFilter>
          </th>
          <th pSortableColumn="Equipment_Name" style="white-space:nowrap;">
            Equipment <p-sortIcon></p-sortIcon>
            <p-columnFilter field="Equipment_Name" matchMode="in" display="menu" [showMatchModes]="false" [showOperator]="false" [showAddButton]="false">
              <ng-template pTemplate="filter" let-value let-filter="filterCallback">
                <p-multiSelect [ngModel]="value" [options]="equipments" placeholder="Any" (onChange)="filter($event.value)">
                  <ng-template let-option pTemplate="item">{{option}}</ng-template>
                </p-multiSelect>
              </ng-template>
            </p-columnFilter>
          </th>
          <th pSortableColumn="Supplier_Name" style="white-space:nowrap;">
            Supplier <p-sortIcon></p-sortIcon>
            <p-columnFilter field="Supplier_Name" matchMode="in" display="menu" [showMatchModes]="false" [showOperator]="false" [showAddButton]="false">
              <ng-template pTemplate="filter" let-value let-filter="filterCallback">
                <p-multiSelect [ngModel]="value" [options]="suppliers" placeholder="Any" (onChange)="filter($event.value)">
                  <ng-template let-option pTemplate="item">{{option}}</ng-template>
                </p-multiSelect>
              </ng-template>
            </p-columnFilter>
          </th>
          <th pSortableColumn="SupplierTag" style="white-space:nowrap;">
            SupplierTag <p-sortIcon></p-sortIcon>
            <p-columnFilter type="text" field="SupplierTag" display="menu"></p-columnFilter>
          </th>
          <th pSortableColumn="SystemName_Name" style="white-space:nowrap;">
            SystemName <p-sortIcon></p-sortIcon>
            <p-columnFilter field="SystemName_Name" matchMode="in" display="menu" [showMatchModes]="false" [showOperator]="false" [showAddButton]="false">
              <ng-template pTemplate="filter" let-value let-filter="filterCallback">
                <p-multiSelect [ngModel]="value" [options]="systemNames" placeholder="Any" (onChange)="filter($event.value)">
                  <ng-template let-option pTemplate="item">{{option}}</ng-template>
                </p-multiSelect>
              </ng-template>
            </p-columnFilter>
          </th>
          <th pSortableColumn="DataType_Name" style="white-space:nowrap;">
            DataType <p-sortIcon></p-sortIcon>
            <p-columnFilter field="DataType_Name" matchMode="in" display="menu" [showMatchModes]="false" [showOperator]="false" [showAddButton]="false">
              <ng-template pTemplate="filter" let-value let-filter="filterCallback">
                <p-multiSelect [ngModel]="value" [options]="dataTypes" placeholder="Any" (onChange)="filter($event.value)">
                  <ng-template let-option pTemplate="item">{{option}}</ng-template>
                </p-multiSelect>
              </ng-template>
            </p-columnFilter>
          </th>
          <th pSortableColumn="UoM_Name" style="white-space:nowrap;">
            UoM <p-sortIcon></p-sortIcon>
            <p-columnFilter field="UoM_Name" matchMode="in" display="menu" [showMatchModes]="false" [showOperator]="false" [showAddButton]="false">
              <ng-template pTemplate="filter" let-value let-filter="filterCallback">
                <p-multiSelect [ngModel]="value" [options]="unitOfMeasures" placeholder="Any" (onChange)="filter($event.value)">
                  <ng-template let-option pTemplate="item">{{option}}</ng-template>
                </p-multiSelect>
              </ng-template>
            </p-columnFilter>
          </th>
          <th pSortableColumn="Address" style="white-space:nowrap;">
            Address <p-sortIcon></p-sortIcon>
            <p-columnFilter type="text" field="Address" display="menu"></p-columnFilter>
          </th>
          <th pSortableColumn="PLC_Name" style="white-space:nowrap;">
            PLC <p-sortIcon></p-sortIcon>
            <p-columnFilter field="PLC_Name" matchMode="in" display="menu" [showMatchModes]="false" [showOperator]="false" [showAddButton]="false">
              <ng-template pTemplate="filter" let-value let-filter="filterCallback">
                <p-multiSelect [ngModel]="value" [options]="plcs" placeholder="Any" (onChange)="filter($event.value)">
                  <ng-template let-option pTemplate="item">{{option}}</ng-template>
                </p-multiSelect>
              </ng-template>
            </p-columnFilter>
          </th>
          <th pSortableColumn="ConnectionDate" style="white-space:nowrap;">
            ConnectionDate <p-sortIcon></p-sortIcon>
            <p-columnFilter field="ConnectionDate" display="menu" [matchModeOptions]="matchModeOptions">
              <ng-template pTemplate="filter" let-value let-filter="filterCallback">
                <p-calendar [ngModel]="value" (onSelect)="filter($event)" [inline]="true" dateFormat="dd/mmm/yy"></p-calendar>
              </ng-template>
            </p-columnFilter>
          </th>

          <th pSortableColumn="NodeName" style="white-space:nowrap;">
            NodeName <p-sortIcon></p-sortIcon>
            <p-columnFilter type="text" field="NodeName" display="menu"></p-columnFilter>
          </th>
          <th pSortableColumn="StreamID" style="white-space:nowrap;">
            StreamID <p-sortIcon></p-sortIcon>
            <p-columnFilter type="text" field="StreamID" display="menu"></p-columnFilter>
          </th>
          <th pSortableColumn="TimeSeriesID" style="white-space:nowrap;">
            TimeSeriesID <p-sortIcon></p-sortIcon>
            <p-columnFilter type="text" field="TimeSeriesID" display="menu"></p-columnFilter>
          </th>

          <th *ngFor="let customAttribute of customAttributes" style="white-space:nowrap;">
            {{ customAttribute.name }}
          </th>
        </tr>
      </ng-template>
      <ng-template pTemplate="body" let-tag>
        <tr>
          <td style="width:30px;">
            <p-tableCheckbox [value]="tag"></p-tableCheckbox>
          </td>
          <td style="white-space:nowrap;"><a href="javascript:void(0)" (click)="edit(tag)">{{tag.s7Tag}}</a></td>
          <td style="white-space:nowrap;">{{tag.vessel_Name}}</td>
          <td style="white-space:nowrap;">{{tag.equipmentType_Name}}</td>
          <td style="white-space:nowrap;">{{tag.system_Name}}</td>
          <td style="white-space:nowrap;">{{tag.subGroup_Name}}</td>
          <td style="white-space:nowrap;">{{tag.equipment_Name}}</td>
          <td style="white-space:nowrap;">{{tag.supplier_Name}}</td>
          <td style="white-space:nowrap;">{{tag.supplierTag}}</td>
          <td style="white-space:nowrap;">{{tag.systemName_Name}}</td>
          <td style="white-space:nowrap;">{{tag.dataType_Name}}</td>
          <td style="white-space:nowrap;">{{tag.uoM_Name}}</td>
          <td style="white-space:nowrap;">{{tag.address}}</td>
          <td style="white-space:nowrap;">{{tag.plC_Name}}</td>
          <td style="white-space:nowrap;">{{tag.connectionDate | date:'yyyy-MM-dd'}}</td>
          <td style="white-space:nowrap;">{{tag.nodeName}}</td>
          <td style="white-space:nowrap;">{{tag.streamID}}</td>
          <td style="white-space:nowrap;">{{tag.timeSeriesID}}</td>

          <td *ngFor="let customAttribute of customAttributes" style="white-space:nowrap;">
            {{ getCustomAttribute(tag, customAttribute.id) }}
          </td>
        </tr>
      </ng-template>
      <ng-template pTemplate="paginatorleft">
        <p-button type="button" icon="pi pi-plus" styleClass="p-button-text" label="Add New" routerLink="/tags/create"></p-button>
        <p-button type="button" icon="pi pi-file-excel" styleClass="p-button-text" label="Upload Excel" routerLink="/tags/upload"></p-button>
        <p-button type="button" icon="pi pi-tags" styleClass="p-button-text" label="Generate Missing Stream/Timeline IDs" routerLink="/tags/genids"></p-button>
        <p-button type="button" icon="pi pi-file-export" styleClass="p-button-text" label="Export selected items" (onClick)="exportSelection()" *ngIf="selectedTags.length > 0"></p-button>
        <p-button type="button" icon="pi pi-trash" styleClass="p-button-text" label="Delete selected items" (onClick)="deleteSelection()" *ngIf="selectedTags.length > 0"></p-button>

      </ng-template>
      <ng-template pTemplate="paginatorright">

      </ng-template>
    </p-table>
  </ng-container>
  <ng-template #loading>
    Loading...
  </ng-template>
</div>


<div class="loading" *ngIf="showLoading">
  <div class="loading-spinner">
    <p-progressSpinner></p-progressSpinner>
  </div>
</div>
