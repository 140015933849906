import { Component } from '@angular/core';
import { delay, interval, mergeMap, Observable } from 'rxjs';
import { TagTsIdsGenerationStatus } from '../../../models/TagTsIdsGenerationStatus';
import { TagService } from '../../../services/tag/tag.service';

@Component({
  selector: 'app-tag-gen-ids',
  templateUrl: './tag-gen-ids.component.html',
  styleUrls: ['./tag-gen-ids.component.css']
})
export class TagGenIdsComponent {

  status: TagTsIdsGenerationStatus = { missingCount: -1, errors: [], generating: false }

  constructor(private tagService: TagService) {
    this.fetchInfo();
  }


  fetchInfo() {
    this.tagService.getTagTsIdsGenerationStatus().pipe(delay(2000)).subscribe(x => {
      this.status = x;
      if (this.status.generating)
        this.fetchInfo();

    });
  }


  generate() {
    this.status.generating = true;
    this.tagService.generateMissingTsIds().subscribe();
    this.fetchInfo();
  }

}
