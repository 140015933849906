import { Component } from '@angular/core';
import { Router } from '@angular/router';
import { Observable } from 'rxjs/internal/Observable';
import { SystemName } from '../../../models/SystemName';
import { SystemnameService } from '../../../services/systemname/systemname.service';

@Component({
  selector: 'app-systemname-list',
  templateUrl: './systemname-list.component.html',
  styleUrls: ['./systemname-list.component.css']
})
export class SystemnameListComponent {

  systemnames$ = new Observable<SystemName[]>();

  constructor(
    private router: Router,
    private systemnameService: SystemnameService
  ) { }

  ngOnInit(): void {
    this.systemnames$ = this.systemnameService.getAll();
  }

  edit(systemname: SystemName) {
    this.router.navigateByUrl("/admin/systemname/" + systemname.id);
  }

}
