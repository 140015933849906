import { Component, Input } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { Observable } from 'rxjs/internal/Observable';
import { first } from 'rxjs/operators';
import { SubGroup } from '../../../models/SubGroup';
import { System } from '../../../models/System';
import { SubgroupService } from '../../../services/subgroup/subgroup.service';
import { SystemService } from '../../../services/system/system.service';
import { MessageService } from 'primeng/api';

@Component({
  selector: 'app-system-edit',
  templateUrl: './system-edit.component.html',
  styleUrls: ['./system-edit.component.css']
})
export class SystemEditComponent {

  @Input() id?: number;
  isEdit: Boolean = false;
  system$ = new Observable<System>();
  subgroups$ = new Observable<SubGroup[]>();

  isSubmitDisabled: boolean = false;

  constructor(
    private messageService: MessageService,
    private router: Router,
    private route: ActivatedRoute,
    private subgroupService: SubgroupService,
    private systemService: SystemService
  ) { }

  ngOnInit(): void {

    this.route.params.subscribe(params => {
      this.id = params['id'];
      if (this.id === undefined) {
        // create new
        this.system$ = this.systemService.getNew();
      }
      else {
        // update existing
        this.system$ = this.systemService.get(this.id);
        this.subgroups$ = this.subgroupService.getSubGroupsOfSystem(this.id);
        this.isEdit = true;
      }
    });

  }

  onSubmit(system: System) {

    this.isSubmitDisabled = true;

    var errorFlag = false;

    if (!system.id) {
      errorFlag = true;
      this.messageService.add({ severity: 'error', summary: 'Required Field', detail: 'ID not entered' });
    }

    if (system.name == '') {
      errorFlag = true;
      this.messageService.add({ severity: 'error', summary: 'Required Field', detail: 'Name not entered' });
    }

    if (errorFlag) {
      this.isSubmitDisabled = false;
    }
    else {
      this.systemService.update(system).pipe(first()).subscribe(x => {
        this.router.navigateByUrl("/admin/system");
      });
    }
  }

  subgroup_edit(subgroup: SubGroup) {
    this.router.navigateByUrl("/admin/subgroup/" + subgroup.id);
  }

}
