import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { catchError, finalize, Observable, of } from 'rxjs';
import { Tag } from '../../models/Tag';
import { TagGridView } from '../../models/TagGridView';
import { environment } from '../../../environments/environment';
import { LazyLoadEvent } from 'primeng/api/lazyloadevent';
import { TotalisedResponse } from '../../models/TotalisedResponse';
import { ErrorService } from '../error/error.service';
import { TagTsIdsGenerationStatus } from '../../models/TagTsIdsGenerationStatus'


@Injectable({
  providedIn: 'root'
})
export class TagService {

  private apiUrl = environment.apiEndpoint + "/api/tag";
  private operation: string = 'Get list of Tags';


  constructor(private http: HttpClient, private error: ErrorService) { }

  get(id: number): Observable<Tag> {
    return this.http.get<Tag>(this.apiUrl + '?id=' + id);
  }

  getAll(): Observable<Tag[]> {
    return this.http.get<Tag[]>(this.apiUrl + "/GetAll");
  }

  create(tag: Tag, isClockBytes: boolean) {
    return this.http.post<Tag>(this.apiUrl + "/create?isClockBytes=" + isClockBytes.toString(), tag);
  }

  update(tag: Tag, isClockBytes: boolean) {
    return this.http.put<Tag>(this.apiUrl + "/update?isClockBytes=" + isClockBytes.toString(), tag);
  }

  delete(id: number) {
    return this.http.post(this.apiUrl + "/delete?id=" + id, id);
  }

  generateTsIds(tag: Tag) {
    return this.http.post<Tag>(this.apiUrl + "/generateTsIds", tag);
  }


  generateMissingTsIds() {
    return this.http.post(this.apiUrl + "/generateMissingTsIds", {});
  }

  getTagTsIdsGenerationStatus(): Observable<TagTsIdsGenerationStatus> {
    return this.http.get<TagTsIdsGenerationStatus>(this.apiUrl + "/tagTsIdsGenerationStatus");
  }


  getNew() {
    const tag: Tag = {
      id: 0,
      address: '',
      dataType: { id: 0, name: '' },
      description: '',
      equipment: { id: 0, name: '' },
      equipmentType: { id: 0, name: '', rangeStart: 0, rangeEnd: 0 },
      nodeName: '',
      plc: { id: 0, name: '' },
      s7Tag: '',
      samplingRate: null,
      sequence: null,
      streamID: '',
      subGroup: { id: 0, name: '' },
      supplier: { id: 0, name: '' },
      supplierTag: '',
      system: { id: 0, name: '' },
      systemName: { id: 0, name: '' },
      timeSeriesID: '',
      uoM: { id: 0, name: '', description: '' },
      vessel: { id: 0, name: '', vesselCode: '' },
      v2MetaData: '',
      customAttributes: []
    };
    return of(tag);
  }

  getExcelTabNames(file: any): Observable<string[]> {
    const formData: FormData = new FormData();
    formData.append('file', file, file.name);
    return this.http.post<string[]>(this.apiUrl + "/GetExcelTabNames", formData);
  }

  verify(file: any, tabName: string): Observable<string[]> {
    const formData: FormData = new FormData();
    formData.append('file', file, file.name);
    formData.append('tabname', tabName);
    return this.http.post<string[]>(this.apiUrl + "/Verify", formData);
  }

  uploadFile(file: any, tabName: string): Observable<boolean> {
    const formData: FormData = new FormData();
    formData.append('file', file, file.name);
    formData.append('tabname', tabName);
    return this.http.post<boolean>(this.apiUrl + "/Upload", formData);
  }

  getTagsLazy(lazyEvent: LazyLoadEvent): Observable<TotalisedResponse<TagGridView[]>> {
    let url = this.apiUrl + '/lazy';

    const emptyResponse: TotalisedResponse<TagGridView[]> = {
      totalRecords: 0,
      records: [],
    };

    return this.http.post<TotalisedResponse<TagGridView[]>>(url, lazyEvent).pipe(
      catchError(
        this.error.handleError<TotalisedResponse<TagGridView[]>>(
          this.operation,
          'Could not fetch tags',
          emptyResponse
        )
      ),
      finalize(() => this.error.clearError(this.operation))
    );
  }


  exportSelection(tagIds: number[]): Observable<Blob> {
    console.log("tag.service.ts:exportSelection()");    
    return this.http.get(this.apiUrl + "/ExportSelection?tagIds=" + tagIds.toString(), { responseType: 'blob' });
  }


  deleteSelection(tagIds: number[]): Observable<string> {
    console.log("tag.service.ts:deleteSelection()");
    return this.http.get<string>(this.apiUrl + "/DeleteSelection?tagIds=" + tagIds.toString());
  }

}
