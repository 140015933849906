<nav class="nav-main">
  <a routerLink="/tags">Tags</a>
  <a routerLink="/admin" class="selected">Admin</a>
</nav>

<div style="padding:20px;">
  <a routerLink="/">Home</a> &nbsp; / &nbsp; <a routerLink="/admin">Admin</a> &nbsp; / &nbsp; <a routerLink="/admin/subgroup">Sub Group</a>
</div>

<div style="padding:20px;">

  <ng-container *ngIf="subgroup$ | async as subgroup; else loading">

    <p-card>
      <div class="p-card-title">
        Sub Group
      </div>
      <div class="p-card-content">
        <form (ngSubmit)="onSubmit(subgroup)">

          <div class="form-control">
            <label for="subgroup.id">ID:</label>
            <input type="text" id="subgroup.id" name="subgroup.id" pInputText [(ngModel)]="subgroup.id" [readonly]="isEdit" />
          </div>

          <div class="form-control">
            <label for="subgroup.name">Name:</label>
            <input type="text" id="subgroup.name" name="subgroup.name" pInputText [(ngModel)]="subgroup.name" />
          </div>

          <div>
            <button type="submit" label="Save" icon="pi pi-save" iconPos="right" pButton [disabled]="isSubmitDisabled"></button>
          </div>

        </form>
      </div>
    </p-card>

    <div style="margin-top:20px;">
      <p-card>
        <div class="p-card-title">
          System
        </div>
        <div class="p-card-content">
          <ng-container *ngIf="system$ | async as system;">
            <p-table [value]="[system]">
              <ng-template pTemplate="header">
                <tr>
                  <th>ID</th>
                  <th>System</th>
                </tr>
              </ng-template>
              <ng-template pTemplate="body" let-s>
                <tr>
                  <td><a href="javascript:void(0)" (click)="system_edit(s)">{{s.id}}</a></td>
                  <td><a href="javascript:void(0)" (click)="system_edit(s)">{{s.name}}</a></td>
                </tr>
              </ng-template>
            </p-table>
          </ng-container>
        </div>
      </p-card>
    </div>

    <div style="margin-top:20px;">
      <p-card>
        <div class="p-card-title">
          Equipment
        </div>
        <div class="p-card-content">
          <ng-container *ngIf="equipments$ | async as equipments;">
            <p-table [value]="equipments">
              <ng-template pTemplate="header">
                <tr>
                  <th>ID</th>
                  <th>Equipment</th>
                </tr>
              </ng-template>
              <ng-template pTemplate="body" let-equipment>
                <tr>
                  <td><a href="javascript:void(0)" (click)="equipment_edit(equipment)">{{equipment.id}}</a></td>
                  <td><a href="javascript:void(0)" (click)="equipment_edit(equipment)">{{equipment.name}}</a></td>
                </tr>
              </ng-template>
            </p-table>
          </ng-container>
        </div>
      </p-card>
    </div>


  </ng-container>
  <ng-template #loading>
    Loading...
  </ng-template>

</div>
