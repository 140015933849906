import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable, of } from 'rxjs';
import { SubGroup } from '../../models/SubGroup';
import { environment } from '../../../environments/environment';

@Injectable({
  providedIn: 'root'
})
export class SubgroupService {

  private apiUrl = environment.apiEndpoint + "/api/subgroup";

  constructor(private http: HttpClient) { }

  get(id: number): Observable<SubGroup> {
    return this.http.get<SubGroup>(this.apiUrl + '?id=' + id);
  }

  getAll(): Observable<SubGroup[]> {
    return this.http.get<SubGroup[]>(this.apiUrl + "/GetAll");
  }

  getAllVMs(): Observable<SubGroup[]> {
    return this.http.get<SubGroup[]>(this.apiUrl + "/GetAllVMs");
  }

  create(subgroup: SubGroup) {
    return this.http.post<SubGroup>(this.apiUrl + "/create", subgroup);
  }

  update(subgroup: SubGroup) {
    return this.http.put<SubGroup>(this.apiUrl + "/update", subgroup);
  }

  getNew() {
    const subgroup: SubGroup = {
      id: null,
      name: ''
    };
    return of(subgroup);
  }

  getSubGroupForEquipment(equipmentId: number) {
    return this.http.get<SubGroup>(this.apiUrl + "/GetSubGroupForEquipment?equipmentId=" + equipmentId);
  }

  getSubGroupsOfSystem(systemId: number) {
    return this.http.get<SubGroup[]>(this.apiUrl + "/GetSubGroupsOfSystem?systemId=" + systemId);
  }

}
