import { Injectable, SimpleChanges } from '@angular/core';
import { Observable } from 'rxjs/internal/Observable';
import { of } from 'rxjs/internal/observable/of';
import { environment } from '../../../environments/environment';
import { OutstandingError } from '../../models/OutstandingError';
import { MessageService } from 'primeng/api';

@Injectable({
  providedIn: 'root'
})
export class ErrorService {


  outstandingErrors: OutstandingError[] = [];

  constructor(private messageService: MessageService) { }

  /**
   * Handle Http operation that failed.
   * Let the app continue.
   * @param operation - name of the operation that failed
   * @param result - optional value to return as the observable result
   */


  // - WHAT IS THIS, WHAT DOES IT DO, DO WE NEED IT?
  //ngDoCheck() {
  //  environment.changeDetection(this.constructor.name)
  //}

  //ngOnChanges(changes: SimpleChanges): void {
  //  environment.trace(changes, this.constructor.name)
  //}
  // ---

  public handleError<T>(operation = 'operation', errorMessage: string, result?: T) {

    this.outstandingErrors.push(
      {
        id: + new Date(),
        operation: operation,
        message: errorMessage,
        timestamp: new Date()
      }
    )
    return (error: any): Observable<T> => {
      this.messageService.add({ severity: 'error', summary: "Error", detail: errorMessage });
      //Todo - Log error remotely by magic
      console.error(error);

      // Let the app keep running by returning an empty result.
      return of(result as T);
    };
  }

  public throwErrorMessage(operation = 'operation', errorMessage: string) {
    this.outstandingErrors.push(
      {
        id: + new Date(),
        operation: operation,
        message: errorMessage,
        timestamp: new Date()
      }
    )
    this.messageService.add({ severity: 'error', summary: "Error", detail: errorMessage });
  }

  public clearError(operation: string) {
    this.outstandingErrors = this.outstandingErrors.filter(e => e.operation != operation);
  }

}
