<nav class="nav-main">
  <a routerLink="/tags">Tags</a>
  <a routerLink="/admin" class="selected">Admin</a>
</nav>

<div style="padding:20px;">
  <a routerLink="/">Home</a> &nbsp; / &nbsp; <a routerLink="/admin">Admin</a> &nbsp; / &nbsp; <a routerLink="/admin/equipmenttype">Equipment Type</a>
</div>

<div style="padding:20px;">
  <ng-container *ngIf="equipmenttypes$ | async as equipmenttypes; else loading">

    <div>
      <p-card>
        <div class="p-card-title">
          Equipment Type
        </div>

        <div style="padding:20px 0px;">
          <button class="btn" routerLink="/admin/equipmenttype/create" label="Create New Equipment Type" pButton icon="pi pi-plus"></button>
        </div>

        <div class="p-card-content">
          <p-table [value]="equipmenttypes">
            <ng-template pTemplate="caption">
              Equipment Type
            </ng-template>
            <ng-template pTemplate="header">
              <tr>
                <th>ID</th>
                <th>Name</th>
                <th>Sequence from</th>
                <th>Sequence to</th>
                <th>Created</th>
                <th>Created By</th>
                <th></th>
              </tr>
            </ng-template>
            <ng-template pTemplate="body" let-equipmenttype>
              <tr>
                <td>{{ equipmenttype.id }}</td>
                <td>{{ equipmenttype.name }}</td>
                <td>{{ equipmenttype.rangeStart }}</td>
                <td>{{ equipmenttype.rangeEnd }}</td>
                <td>{{ equipmenttype.created | date:'yyyy-MM-dd HH:mm' }}</td>
                <td>{{ equipmenttype.createdBy }}</td>
                <td><a href="javascript:void(0)" (click)="edit(equipmenttype)">edit</a></td>
              </tr>
            </ng-template>
          </p-table>
        </div>
      </p-card>
    </div>

  </ng-container>
  <ng-template #loading>
    Loading...
  </ng-template>
</div>

