import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable, of } from 'rxjs';
import { Equipment } from '../../models/Equipment';
import { environment } from '../../../environments/environment';

@Injectable({
  providedIn: 'root'
})
export class EquipmentService {

  private apiUrl = environment.apiEndpoint + "/api/equipment";

  constructor(private http: HttpClient) { }

  get(id: number): Observable<Equipment> {
    return this.http.get<Equipment>(this.apiUrl + '?id=' + id);
  }

  getAll(): Observable<Equipment[]> {
    return this.http.get<Equipment[]>(this.apiUrl + "/GetAll");
  }

  getAllVMs(): Observable<Equipment[]> {
    return this.http.get<Equipment[]>(this.apiUrl + "/GetAllVMs");
  }

  create(equipment: Equipment) {
    console.log("equipment.service.ts");
    console.log(equipment);
    return this.http.post<Equipment>(this.apiUrl + "/create", equipment);
  }

  update(equipment: Equipment) {
    return this.http.put<Equipment>(this.apiUrl + "/update", equipment);
  }

  getNew() {
    const equipment: Equipment = {
      id: null,
      name: ''
    };
    return of(equipment);
  }

  getEquipmentOfSubGroup(subgroupId: number) {
    return this.http.get<Equipment[]>(this.apiUrl + "/GetEquipmentOfSubGroup?subgroupId=" + subgroupId);
  }

}
