<div style="padding:20px;">
  <ng-container *ngIf="tag$ | async as tag; else loading">
    <form (ngSubmit)="onSubmit(tag)">

      <table>

        <tr>
          <td>
            <label for="tag.vessel">Vessel:</label>
          </td>
          <td>
            <p-dropdown *ngIf="doCreate" [options]="vessels" optionLabel="name" [(ngModel)]="tag.vessel" name="tag.vessel" id="tag.vessel" styleClass="width-300" placeholder="&nbsp;"></p-dropdown>
            <span *ngIf="!doCreate" style="display: inline-block; border: solid 1px #CED4DA; padding: 9px; border-radius: 6px; font-size: 1rem; min-width: 300px; height: 38px;">{{ tag.vessel.name }} [{{ tag.vessel.vesselCode }}]</span>
          </td>
        </tr>

        <tr>
          <td>
            <label for="tag.equipmentType">Equipment Type:</label>
          </td>
          <td>
            <p-dropdown *ngIf="doCreate" [options]="equipmentTypes" optionLabel="name" [(ngModel)]="tag.equipmentType" name="tag.equipmentType" id="tag.equipmentType" styleClass="width-300" placeholder="&nbsp;"></p-dropdown>
            <span *ngIf="!doCreate" style="display: inline-block; border: solid 1px #CED4DA; padding: 9px; border-radius: 6px; font-size: 1rem; min-width: 300px; height: 38px;">{{ tag.equipmentType.name }}</span>
          </td>
        </tr>

        <tr>
          <td>
            <label for="tag.equipment">Equipment:</label>
          </td>
          <td>
            <p-dropdown *ngIf="doCreate" [options]="equipments" optionLabel="name" [(ngModel)]="tag.equipment" name="tag.equipment" id="tag.equipment" styleClass="width-300" placeholder="&nbsp;" (onChange)="equipment_onChange($event)"></p-dropdown>
            <span *ngIf="!doCreate" style="display: inline-block; border: solid 1px #CED4DA; padding: 9px; border-radius: 6px; font-size: 1rem; min-width: 300px; height: 38px;">{{ tag.equipment?.name }}</span>
          </td>
        </tr>

        <tr>
          <td>
            <label for="tag.system">System:</label>
          </td>
          <td>
            <!--<p-dropdown [options]="systems" optionValue="id" optionLabel="name" [(ngModel)]="tag.system" name="tag.system" id="tag.system"></p-dropdown>-->
            <span style="display: inline-block; border: solid 1px #CED4DA; padding: 9px; border-radius: 6px; font-size: 1rem; min-width: 300px; height: 38px;">{{ tag.system?.name }}</span>
          </td>
        </tr>

        <tr>
          <td>
            <label for="tag.subGroup">Sub Group:</label>
          </td>
          <td>
            <!--<p-dropdown [options]="subGroups" optionValue="id" optionLabel="name" [(ngModel)]="tag.subGroup" name="tag.subGroup" id="tag.subGroup"></p-dropdown>-->
            <span style="display: inline-block; border: solid 1px #CED4DA; padding: 9px; border-radius: 6px; font-size: 1rem; min-width: 300px; height: 38px;">{{ tag.subGroup?.name }}</span>
          </td>
        </tr>

        <tr>
          <td>
            <label for="tag.s7Tag">S7 Tag:</label>
          </td>
          <td>
            <!--<input type="text" id="tag.s7Tag" name="tag.s7Tag" pInputText [(ngModel)]="tag.s7Tag" />-->
            <span style="display: inline-block; border: solid 1px #CED4DA; padding: 9px; border-radius: 6px; font-size: 1rem; min-width: 300px; height: 38px;">{{ tag.s7Tag }}</span>
          </td>
        </tr>

        <tr>
          <td>
            <label for="tag.address">Address:</label>
          </td>
          <td>
            <input type="text" id="tag.address" name="tag.address" pInputText [(ngModel)]="tag.address" style="width:300px;" />
          </td>
        </tr>

        <tr>
          <td>
            <label for="tag.dataType">Data Type:</label>
          </td>
          <td>
            <p-dropdown [options]="dataTypes"
                        optionLabel="name"
                        optionValue="id"
                        [(ngModel)]="tag.dataType.id"
                        name="tag.dataType"
                        id="tag.dataType"
                        styleClass="width-300"
                        placeholder="&nbsp;">
            </p-dropdown>
          </td>
        </tr>

        <tr>
          <td>
            <label for="tag.description">Description:</label>
          </td>
          <td>
            <input type="text" id="tag.description" name="tag.description" pInputText [(ngModel)]="tag.description" style="width:300px;" />
          </td>
        </tr>

        <tr>
          <td>
            <label for="tag.plc">PLC:</label>
          </td>
          <td>
            <p-dropdown [options]="plcs"
                        optionLabel="name"
                        optionValue="id"
                        [(ngModel)]="tag.plc.id"
                        name="tag.plc"
                        id="tag.plc"
                        styleClass="width-300"
                        placeholder="&nbsp;">
            </p-dropdown>
          </td>
        </tr>

        <tr>
          <td>
            <label for="tag.samplingRate">Sampling Rate:</label>
          </td>
          <td>
            <input type="text" id="tag.samplingRate" name="tag.samplingRate" pInputText [(ngModel)]="tag.samplingRate" style="width:300px;" />
          </td>
        </tr>

        <tr>
          <td>
            <label for="tag.sequence">Sequence:</label>
          </td>
          <td>
            <!--<input type="text" id="tag.sequence" name="tag.sequence" pInputText [(ngModel)]="tag.sequence" style="width:300px;" />-->
            <span style="display: inline-block; border: solid 1px #CED4DA; padding: 9px; border-radius: 6px; font-size: 1rem; min-width: 300px; height: 38px;">{{ tag.sequence }}</span>
          </td>
        </tr>

        <tr>
          <td>
            <label for="tag.supplier">Supplier:</label>
          </td>
          <td>
            <p-dropdown [options]="suppliers"
                        optionLabel="name"
                        optionValue="id"
                        [(ngModel)]="tag.supplier.id"
                        name="tag.supplier"
                        id="tag.supplier"
                        styleClass="width-300"
                        placeholder="&nbsp;">
            </p-dropdown>
          </td>
        </tr>

        <tr>
          <td>
            <label for="tag.supplierTag">Supplier Tag:</label>
          </td>
          <td>
            <input type="text" id="tag.supplierTag" name="tag.supplierTag" pInputText [(ngModel)]="tag.supplierTag" style="width:300px;" />
          </td>
        </tr>

        <tr>
          <td>
            <label for="tag.systemName">System Name:</label>
          </td>
          <td>
            <p-dropdown [options]="systemNames"
                        optionLabel="name"
                        optionValue="id"
                        [(ngModel)]="tag.systemName.id"
                        name="tag.systemName"
                        id="tag.systemName"
                        styleClass="width-300"
                        placeholder="&nbsp;">
            </p-dropdown>
          </td>
        </tr>

        <tr>
          <td>
            <label for="tag.uoM">Unit of Measure:</label>
          </td>
          <td>
            <p-dropdown [options]="unitOfMeasures"
                        optionLabel="name"
                        optionValue="id"
                        [(ngModel)]="tag.uoM.id"
                        name="tag.uoM"
                        id="tag.uoM"
                        styleClass="width-300"
                        placeholder="&nbsp;">
            </p-dropdown>
          </td>
        </tr>

        <tr>
          <td>
            <label for="tag.connectionDate">Connection Date:</label>
          </td>
          <td>
            <!--<p-calendar [(ngModel)]="tag.connectionDate" id="tag.connectionDate" name="tag.connectionDate"></p-calendar>-->
            <span style="display: inline-block; border: solid 1px #CED4DA; padding: 9px; border-radius: 6px; font-size: 1rem; min-width: 300px; height: 38px;">{{ tag.connectionDate | date:'yyyy-MM-dd' }}</span>
          </td>
        </tr>

        <tr>
          <td>
            <label for="tag.nodeName">Node Name:</label>
          </td>
          <td>
            <!--<input type="text" id="tag.nodeName" name="tag.nodeName" pInputText [(ngModel)]="tag.nodeName" />-->
            <span style="display: inline-block; border: solid 1px #CED4DA; padding: 9px; border-radius: 6px; font-size: 1rem; min-width: 300px; height: 38px;">
              {{ tag.plc.name }}.CPU0.{{ tag.s7Tag }}
            </span>
          </td>
        </tr>

        <tr>
          <td>
            <label for="tag.streamID">Stream ID:</label>
          </td>
          <td>
            <!--<input type="text" id="tag.streamID" name="tag.streamID" pInputText [(ngModel)]="tag.streamID" />-->
            <span style="display: inline-block; border: solid 1px #CED4DA; padding: 9px; border-radius: 6px; font-size: 1rem; min-width: 300px; height: 38px;">{{ tag.streamID }}</span>
          </td>
        </tr>

        <tr>
          <td>
            <label for="tag.timeSeriesID">Time Series ID:</label>
          </td>
          <td>
            <!--<input type="text" id="tag.timeSeriesID" name="tag.timeSeriesID" pInputText [(ngModel)]="tag.timeSeriesID" />-->
            <span style="display: inline-block; border: solid 1px #CED4DA; padding: 9px; border-radius: 6px; font-size: 1rem; min-width: 300px; height: 38px;">{{ tag.timeSeriesID }}</span>
          </td>
        </tr>

        <tr>
          <td></td>
          <td>
            <p-button *ngIf="tag.streamID === null && tag.id > 0 && !generatingTsIds" type="button" (click)="onGenTsIds(tag)" [style]="{width:'100%'}" label="Generate time series and stream IDs" icon="pi pi-tags" iconPos="right" styleClass="p-button-raised p-button-sm p-button-secondary"></p-button>
            <p-button *ngIf="generatingTsIds" type="button" [disabled]="true"  [style]="{width:'100%'}" label="Generating..." icon="pi pi-tags" iconPos="right" styleClass="p-button-raised p-button-sm p-button-secondary"></p-button>
            <!--<p-progressSpinner [style]="{'width': '10px', 'height': '10px'}" strokeWidth="1" fill="var(--surface-ground)" animationDuration=".5s"></p-progressSpinner>-->
          </td>
        </tr>

        <!--<tr>
          <td>
            <label for="tag.v2MetaData">V2 Meta Data:</label>
          </td>
          <td>
            <span style="display: inline-block; border: solid 1px #CED4DA; padding: 9px; border-radius: 6px; font-size: 1rem; min-width: 300px; height: 38px;">{{ tag.v2MetaData }}</span>
          </td>
        </tr>-->


        

        <tr *ngFor="let customAttribute of tag.customAttributes">
          <td>
            <label for="tab.customAttribute_{{ customAttribute.id }}">{{ customAttribute.name }}</label>
          </td>
          <td>
            <input type="text" id="tag.customAttribute_{{ customAttribute.id }}" name="tag.customAttribute_{{ customAttribute.id }}" [(ngModel)]="customAttribute.value" pInputText style="width:300px;" />
          </td>
        </tr>


      </table>

      <div style="margin-top:20px">
        <p-button type="submit" label="Save" icon="pi pi-save" iconPos="right" styleClass="p-button-raised" [disabled]="isSubmitDisabled"></p-button>
        <p-button type="button" label="Delete" icon="pi pi-trash" iconPos="right" (click)="onConfirmDelete(tag)" styleClass="p-button-raised p-button-danger" style="margin-left:10px;"></p-button>
      </div>

    </form>
  </ng-container>
  <ng-template #loading>
    Loading...
  </ng-template>
</div>
