<nav class="nav-main">
  <a routerLink="/tags">Tags</a>
  <a routerLink="/admin" class="selected">Admin</a>
</nav>

<div style="padding:20px;">
  <a routerLink="/">Home</a> &nbsp; / &nbsp; <a routerLink="/admin">Admin</a> &nbsp; / &nbsp; <a routerLink="/admin/vessel">Vessels</a> &nbsp; / &nbsp; <span *ngIf="vessel$ | async as vessel">{{vessel.name}}</span>
</div>

<div style="padding:20px;">
  <ng-container *ngIf="vessel$ | async as vessel; else loading">
    <form (ngSubmit)="onSubmit(vessel)">

      <div class="form-control">
        <label for="vessel.name">Name:</label>
        <input type="text" id="vessel.name" name="vessel.name" pInputText [(ngModel)]="vessel.name" />
      </div>

      <div class="form-control">
        <label for="vessel.vesselCode">Vessel Code:</label>
        <input type="text" id="vessel.vesselCode" name="vessel.vesselCode" pInputText [(ngModel)]="vessel.vesselCode" />
      </div>

      <div>
        <p-button type="submit" label="Save" icon="pi pi-save" iconPos="right" styleClass="p-button-raised" [disabled]="isSubmitDisabled"></p-button>
        <p-button type="button" label="Delete" icon="pi pi-trash" iconPos="right" (click)="onConfirmDelete(vessel)" styleClass="p-button-raised p-button-danger" style="margin-left:10px;"></p-button>
      </div>

    </form>
  </ng-container>
  <ng-template #loading>
    Loading...
  </ng-template>
</div>
