import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable, of } from 'rxjs';
import { System } from '../../models/System';
import { environment } from '../../../environments/environment';

@Injectable({
  providedIn: 'root'
})
export class SystemService {

  private apiUrl = environment.apiEndpoint + "/api/system";

  constructor(private http: HttpClient) { }

  get(id: number): Observable<System> {
    return this.http.get<System>(this.apiUrl + '?id=' + id);
  }

  getAll(): Observable<System[]> {
    return this.http.get<System[]>(this.apiUrl + "/GetAll");
  }

  getAllVMs(): Observable<System[]> {
    return this.http.get<System[]>(this.apiUrl + "/GetAllVMs");
  }

  create(system: System) {
    return this.http.post<System>(this.apiUrl + "/create", system);
  }

  update(system: System) {
    return this.http.put<System>(this.apiUrl + "/update", system);
  }

  getNew() {
    const system: System = {
      id: null,
      name: ''
    };
    return of(system);
  }

  getSystemForSubGroup(subgroupId: number) {
    return this.http.get<System>(this.apiUrl + "/GetSystemForSubGroup?subgroupId=" + subgroupId);
  }
}
